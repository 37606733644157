<p-toast [style]="{ marginBottom: '25px' }" position="bottom-right"></p-toast>
<p-panel>
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2">
      <h5 class="mt-3">Update Company Settings</h5>
      <br />
    </div>
  </ng-template>
  <div [formGroup]="manufacturerForm">
    <div class="grid">
      <div class="col-4" *ngIf="this.isRPAdmin || (manufacturers | async)?.length > 1">
        <div class="text-left p-4">
          <div class="form-label">Company</div>
          <p-dropdown
            styleClass="w-full"
            id="manufacturerId"
            [options]="manufacturers | async"
            optionLabel="name"
            optionValue="id"
            formControlName="manufacturerId"
            [filter]="(manufacturers | async)?.length > 1"
            [disabled]="(manufacturers | async)?.length <= 1"
            (onChange)="onManufacturerChanged($event)"
          ></p-dropdown>
        </div>
      </div>
      <div class="col-1 flex align-items-center">
        <p-checkbox id="isTestCompany" name="isTestCompany" formControlName="isTestCompany" binary="true"></p-checkbox>
        <label class="ml-2 mb-0" for="isTestCompany">Test</label>
      </div>
      <div class="col-3">
        <div class="text-left p-4">
          <div class="form-label">Name</div>
          <input
            type="text"
            class="p-inputtext-sm"
            pInputText
            formControlName="name"
            maxlength="100"
            style="width: 100%"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label">&nbsp;</div>
          <div class="flex align-items-center">
            <p-checkbox
              #wrxEnabled
              name="roofingWRXEnabled"
              value="{{ enableWRX }}"
              [formControl]="manufacturerForm.controls['roofingWRXEnabled']"
              binary="true"
              (onChange)="onChecked(wrxEnabled)"
            ></p-checkbox>
            <label class="ml-2">RoofingWRX Enabled</label>
          </div>

          <div class="flex align-items-center">
            <p-checkbox
              #wrxJobsEnabled
              name="roofingWRXJobsEnabled"
              binary="true"
              value="{{ enableWRXJobs }}"
              [formControl]="manufacturerForm.controls['roofingWRXJobsEnabled']"
              (onChange)="onChecked(wrxJobsEnabled)"
            ></p-checkbox>
            <label class="ml-2">RoofingWRX Jobs Enabled</label>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label">Terms of Service Url</div>
          <input
            type="text"
            class="p-inputtext-sm"
            pInputText
            formControlName="termsUrl"
            maxlength="100"
            style="width: 100%"
          />
        </div>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="grid">
      <div class="col-6">
        <div class="text-left p-4">
          <div class="form-label">XML Disabled Message</div>
          <textarea pInputTextarea class="width-100-pct" formControlName="xmlDisabledMessage" rows="3"></textarea>
        </div>
      </div>
      <div class="col-12">
        <p-divider></p-divider>
        <div class="text-left p-4">
          <i class="pi pi-exclamation-circle"></i>
          <span><b> Do not modify the settings below unless you know what you are doing!</b></span>
        </div>
      </div>
      <div class="col-4">
        <div class="text-left p-4">
          <div class="form-label">Roofing WRX Company ID</div>
          <input
            type="text"
            class="p-inputtext-sm"
            pInputText
            formControlName="roofingWRXCompanyId"
            maxlength="100"
            style="width: 100%"
          />
          <div class="form-error">
            <span *ngIf="roofingWRXCompanyId.invalid && (roofingWRXCompanyId.touched || roofingWRXCompanyId.dirty)"
              >A RoofingWRX Company Id must be an integer</span
            >
          </div>
        </div>
      </div>
    </div>
    <p-divider></p-divider>
  </div>
  <br />
  <div class="grid">
    <div class="col-8"></div>
    <div class="col-4">
      <div class="text-right p-4">
        <button
          pButton
          pRipple
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text p-button-secondary"
          (click)="returnToProjectList()"
        ></button>
        <p-button
          label="Update"
          class="p-button-secondary"
          type="submit"
          [disabled]="!manufacturerForm.valid"
          (onClick)="save()"
        ></p-button>
      </div>
    </div>
  </div>
</p-panel>
